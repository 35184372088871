import { Component, Input } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";

@Component({
  selector: "letter-counter",
  templateUrl: "./letter-counter.component.html",
  styleUrls: ["./letter-counter.component.scss"]
})
export class LetterCounterComponent extends BaseComponent {

  @Input() title: string | null = null;
  @Input() subtitle: string | null = null;
  @Input() count: number = 0;
}
