<ngb-pagination
  class="align-self-center"
  [collectionSize]="collectionSize"
  [maxSize]="maxSize"
  [rotate]="true"
  [(page)]="currentPage"
  [pageSize]="pageSize"
  (pageChange)="onPageChange($event)"
  id="pagination"
>
</ngb-pagination>
