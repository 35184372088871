import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";
import { Sizes, Variants } from "../../util/general-types";


@Component({
  selector: "title-subtitle-page",
  templateUrl: "./title-subtitle-page.component.html",
  styleUrls: ["./title-subtitle-page.component.scss"]
})
export class TitleSubtitlePageComponent extends BaseComponent {

  @Input() title: string | null = null;
  @Input() subtitle: string | null = null;
  @Input() facoltativeSubtitle: string | null = null;
  @Input() buttonText: string | null = null;
  @Input() buttonVariant: Variants = "secondary-light";
  @Input() buttonSize: Sizes = "md";
  @Input() buttonDisabled: boolean = false;
  @Output() onClickButton = new EventEmitter<void>();
}
