import { Component } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";
import { UrlCollection } from "src/app/app-routing.module";


@Component({
  selector: "header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent extends BaseComponent {

  notificationsCounter: number = 0;

  protected async onLogoClicked() {
    await this.router.navigate([UrlCollection.homepage]);
  }

  openNotifications() {
    
  }

  closeNotifications() {
    
  }
}
