<main aria-label="Contenuto principale della pagina" tabindex="-1">
  <div class="content padding-top16">
    <title-subtitle-page
      title="{{ 'homepage.TITLE' | translate }}"
      subtitle="{{ 'homepage.SUBTITLE' | translate }}"
      buttonVariant="secondary-light"
      buttonText="{{ 'homepage.CREATE_NEW_SEND_SESSION' | translate }}"
      [buttonDisabled]="genericLoader"
      (onClickButton)="openCreateSessionSidebar()"
    ></title-subtitle-page>

    <ng-template #contentSidebar let-offcanvas>
      <div class="offcanvas-body">
        <div class="create-session-container d-flex flex-column">
          <p tabindex="0" class="title" id="content-sidebar-title">
            {{ "createSession.CREATE_NEW_SESSION" | translate }}
          </p>
          <p class="subtitle">
            {{ "createSession.CREATE_NEW_SESSION_DESC" | translate }}
          </p>
          <div class="divisor"></div>
          <div
            class="actions-container d-flex flex-column justify-content-start align-items-center"
          >
            <div class="first-action-container d-flex flex-column w-100">
              <div
                class="d-flex flex-row justify-content-start align-items-center"
              >
                <p class="text-action red margin-right4">1.</p>
                <p class="text-action">
                  {{ "createSession.SELECT_YEAR" | translate }}
                </p>
              </div>
              <custom-select
                *ngIf="years && years.length && !yearsLoader"
                [placeholder]="''"
                [labelForId]="'input-select-year'"
                bindLabel="title"
                containerClass="cursor-pointer full-dimension custom-select gray-background"
                [bindModel]="selectedYear"
                [items]="years"
                [attrAriaLabel]="'generic.SELECT' | translate"
                [placeholderLabel]="
                  'createSession.SELECT_YEAR_PLACEHOLDER' | translate
                "
                [required]="true"
                (onSelectionChanged)="onSelectedYearChanged($event)"
                [disabled]="
                  !years ||
                  years.length === 0 ||
                  (companySelected && yearSelected)
                "
              >
              </custom-select>
              <div *ngIf="yearsLoader">
                <loader marginBlock="20px" width="50px"></loader>
              </div>
            </div>

            <div
              *ngIf="yearSelected"
              class="second-action-container d-flex flex-column w-100"
            >
              <div
                class="d-flex flex-row justify-content-start align-items-center"
              >
                <p class="text-action red margin-right4">2.</p>
                <p class="text-action">
                  {{ "createSession.SELECT_COMPANY" | translate }}
                </p>
              </div>
              <custom-select
                bindLabel="title"
                containerClass="cursor-pointer full-dimension custom-select gray-background"
                [bindModel]="selectedCompany"
                [items]="companies"
                [labelForId]="'input-select-company'"
                [attrAriaLabel]="'generic.SELECT' | translate"
                bindLabel="name"
                [required]="true"
                [parameterNameOfSelectedItem]="'name'"
                [placeholderLabel]="
                  'createSession.SELECT_COMPANY_PLACEHOLDER' | translate
                "
                bindValue="companyId"
                (onSelectionChanged)="onSelectedCompanyChanged($event)"
                [disabled]="
                  !companies ||
                  companies.length === 0 ||
                  (companySelected && yearSelected)
                "
              >
              </custom-select>
            </div>

            <div
              *ngIf="yearSelected && companySelected"
              class="third-action-container d-flex flex-column w-100"
            >
              <div
                class="d-flex flex-row justify-content-start align-items-center"
              >
                <p class="text-action red margin-right4">3.</p>
                <p class="text-action">
                  {{
                    "createSession.DOWNLOAD_AND_VERIFY_LETTER_TEMPLATES"
                      | translate
                  }}
                </p>
              </div>
              <mbo-button
                (onClick)="downloadLetterTemplates()"
                [variant]="'secondary-ghost-light'"
                text="{{ 'createSession.DOWNLOAD_ALL_MODELS' | translate }}"
                [size]="'md'"
                [iconLeftSrc]="'./assets/icons/download-outline.svg'"
              ></mbo-button>
            </div>

            <div
              *ngIf="yearSelected && companySelected"
              class="fourth-action-container d-flex flex-column w-100"
            >
              <div
                class="gap4 d-flex flex-column justify-content-start align-items-center"
              >
                <div
                  class="w-100 d-flex flex-row justify-content-start align-items-center"
                >
                  <p class="text-action red margin-right4">4.</p>
                  <p class="text-action">
                    {{ "createSession.CHARGE_EXCEL_WITH_DATA" | translate }}
                  </p>
                </div>
                <p class="text-action font-weight400">
                  {{ "createSession.CHARGE_EXCEL_WITH_DATA_DESC" | translate }}
                </p>
              </div>
              <mbo-button
                (onClick)="downloadTracciato()"
                [variant]="'secondary-ghost-light'"
                text="{{ 'createSession.DOWNLOAD_TRACCIATO' | translate }}"
                [size]="'md'"
                [iconLeftSrc]="'./assets/icons/download-outline.svg'"
              ></mbo-button>

              <div>
                <p class="text-action margin-bottom4">
                  {{ "createSession.CHARGE_FILE_WITH_DATA" | translate }}
                </p>
                <div [hidden]="fileUploadLoader" class="input-group custom-file-button">
                  <label class="input-group-text" for="inputGroupFile">{{
                    "createSession.CHOOSE_FILE" | translate
                  }}</label>
                  <input
                    type="file"
                    class="form-control"
                    id="inputGroupFile"
                    (click)="clickInputFile($event)"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    (change)="
                      onFileChange($event, modalValidateFileErrorsContent)
                    "
                  />
                </div>
                <div *ngIf="fileUploadLoader">
                  <loader marginBlock="20px" width="50px"></loader>
                </div>
              </div>
            </div>

            <div
              class="button-container d-flex w-100 justify-content-center align-items-center"
            >
              <mbo-button
                (onClick)="closeSidebar()"
                [variant]="'secondary-ghost-light'"
                text="{{ 'generic.ANNULL' | translate }}"
                [size]="'md'"
              ></mbo-button>
              <mbo-button
                (onClick)="continueCreateSession()"
                [variant]="'secondary-light'"
                text="{{ 'generic.PROCEED' | translate }}"
                [size]="'md'"
                [disabled]="disabledContinueCreateSession()"
              ></mbo-button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #modalValidateFileErrorsContent let-modal>
      <div class="modal-body">
        <div class="w-100 d-flex justify-content-end">
          <link-button
            class="cursor-pointer"
            (onClick)="closeValidateFileErrorsModal()"
            variant="primary"
            text="{{ 'generic.CLOSE' | translate }}"
          ></link-button>
        </div>
        <div
          id="modal-validate-file-errors-title"
          class="modal-validate-file-errors-title w-100 d-flex justify-content-start margin-top16"
        >
          <p tabindex="0">
            {{ "createSession.VALIDATE_FILE_ERRORS_LIST" | translate }}
          </p>
        </div>
        <p
          class="validate-file-errors-modal-description margin-top16"
          [innerHTML]="validateFileErrors"
        ></p>
      </div>
    </ng-template>

    <ng-container *ngIf="!genericLoader; else loading">
      <div
        class="no-session-found-container d-flex justify-content-start align-items-center"
        *ngIf="!sessionsCount || !sessions.length"
      >
        <p>{{ "homepage.NO_SESSION_FOUND" | translate }}</p>
      </div>
      <div class="margin-top16" *ngIf="sessionsCount && sessions?.length">
        <div class="table-responsive">
          <table class="table table-striped table-light">
            <thead>
              <tr class="align-middle">
                <th scope="col">
                  <p>{{ "homepage.YEAR" | translate }}</p>
                </th>
                <th scope="col">
                  <p>{{ "homepage.COMPANY" | translate }}</p>
                </th>
                <th scope="col">
                  <p>{{ "homepage.GENERATED_LETTERS" | translate }}</p>
                </th>
                <th scope="col">
                  <p>{{ "homepage.SEND_DATE" | translate }}</p>
                </th>
                <th scope="col">
                  <p class="red-text">{{ "homepage.ACTIONS" | translate }}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="align-middle" *ngFor="let session of sessions">
                <td>
                  <p>{{ session.year }}</p>
                </td>
                <td>
                  <p>{{ session.companyName }}</p>
                </td>
                <td>
                  <p>{{ session.generatedLettersString }}</p>
                </td>
                <td *ngIf="session.lettersSendingDate">
                  <p>
                    {{ session.lettersSendingDate | date : "dd/MM/yyyy" }}
                  </p>
                </td>
                <td *ngIf="!session.lettersSendingDate">
                  <tag
                    text="{{ 'homepage.TO_SEND' | translate }}"
                    variant="primary"
                  ></tag>
                </td>
                <td>
                  <link-button
                    class="cursor-pointer d-flex justify-content-center"
                    (onClick)="goToSessionDetail(session.sessionId)"
                    variant="primary-red"
                    text="{{ 'homepage.SESSION_DETAILS' | translate }}"
                  ></link-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="pagination-container d-flex justify-content-between align-items-center"
        >
          <div></div>
          <pagination
            [collectionSize]="sessionsCount || 0"
            [maxSize]="3"
            [currentPage]="currentPage"
            [pageSize]="pageSize"
            (pageChange)="onPageChange($event)"
          >
          </pagination>

          <div
            class="pagination-elements-container d-flex align-items-center justify-content-center"
          >
            <custom-select
              [attrAriaLabel]="'generic.SELECT' | translate"
              [placeholder]="''"
              [labelForId]="'input-change-pagination-homepage'"
              [ieFlexProblem]="true"
              [required]="true"
              [bindModel]="pageSize"
              (onSelectionChanged)="onOptionChanged($event)"
              [items]="pageSizes"
              containerClass="custom-select select-searchbar-admin"
              loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}"
              [virtualScroll]="false"
            >
            </custom-select>
            <p class="">{{ "homepage.ELEMENTS" | translate }}</p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #loading>
      <loader marginBlock="200px"></loader>
    </ng-template>
  </div>
</main>
