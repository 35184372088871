<h1>{{ title }}</h1>
<div
  [ngClass]="{ 'flex-column': facoltativeSubtitle }"
  class="d-flex justify-content-between align-items-start"
>
  <p *ngIf="facoltativeSubtitle" class="default-subtitle">
    {{ facoltativeSubtitle }}
  </p>
  <div
    class="d-flex justify-content-between align-items-center w-100"
  >
    <p class="subtitle">{{ subtitle }}</p>
    <mbo-button
      *ngIf="buttonVariant && buttonText && buttonSize"
      [variant]="buttonVariant"
      [text]="buttonText"
      [size]="buttonSize"
      [disabled]="buttonDisabled"
      (onClick)="onClickButton.emit()"
    ></mbo-button>
  </div>
</div>
