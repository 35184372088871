import { Injectable, inject } from "@angular/core";
import { BaseDataService } from "src/ancestors/base-data.service";
import { TokenStorageService } from "./token-storage.service";

export type BaseLoginInfo = any;

@Injectable()
export class UserService extends BaseDataService {
    private tkStorage = inject(TokenStorageService);

    constructor() {
        super();
    }
}
