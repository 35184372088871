<div class="content">
  <div class="d-flex justify-content-between align-items-center header-wrapper">
    <!-- logo Generali -->
    <div class="cursor-pointer" (click)="onLogoClicked()">
      <img src="./assets/icons/logo-generali.svg" alt="" />
    </div>

    <!-- Nome applicazione -->
    <div class="cursor-pointer" (click)="onLogoClicked()">
      <img src="./assets/icons/icon-performance-up.svg" alt="" />
    </div>

    <!-- Notifiche -->
    <div>
    <!-- <button
      (click)="openNotifications()"
      class="cursor-pointer icon-style-header"
      attr.aria-label="{{ 'header.NOTIFICATIONS' | translate }}"
      (clickOutside)="closeNotifications()"
    >
      <img
        src="assets/icons/notify_icon.svg" alt=""
      >
      <span *ngIf="notificationsCounter > 0" class="badge-notify">{{
        notificationsCounter
      }}</span>
    </button> -->
  </div>
  </div>
</div>

<!-- Linea divisoria -->
<div class="header__line"></div>