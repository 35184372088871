import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { BaseComponent } from "src/ancestors/base/base.component";
import { UrlCollection } from "./app-routing.module";
import { TranslateService } from "./shared/util/translate.service";
import { BaseLoginInfo } from "./global-service/user.service";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]

})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  protected fb: FormBuilder = inject(FormBuilder);
  protected translate = inject(TranslateService);

  constructor() {
    super();
    this.logger.setCaller("AppComponent");

    if (!window.location.href.includes(UrlCollection.homepage) && !window.location.href.includes(UrlCollection.localAuthentication)) {
      sessionStorage.setItem("originalRequestedUrl", window.location.href);
    }
  }

  protected showUserOption?: boolean;
  
  // eslint-disable-next-line @typescript-eslint/require-await
  override async ngOnInit(): Promise<void> {
    const loginResposnse: BaseLoginInfo = this.tStorage.retriveToken(this.tokenKey);

    /**
     * 
     * Se esiste un token ed è ancora valido schedula il rinnovo prima della scadenza
    */
    if (!this.appService.isEmpty(loginResposnse) && this.tkManager.checkTokenValidity(loginResposnse)) {
      this.tkManager.refreshTokenScheduler(loginResposnse);
    }

    if (!sessionStorage.getItem("originalRequestedUrl") && this.env.loginType === "saml-sso") {
      await this.router.navigate([UrlCollection.redirectTo]);
    }

    sessionStorage.removeItem("originalRequestedUrl");
  }

  /** Questa funzione cambia la lingua a runtime, e fa il reload della pagina */
  private changeLang(locale: string) {

    /** Salva la lingua nel localStorage */
    localStorage.setItem("locale", locale);

    /** Fa il reload della pagina, per caricare il file json corretto */
    location.reload();
  }

  protected async doLogout() {
    this.showUserOption = false;
    try {
      const redirectToLogin: string = sessionStorage.getItem("SSO_LOGIN") ? "Saml-sso/logoutSaml" : this.env.loginUrl;
      // cancello la variabile che mi dice se ho fatto il login con l'sso 
      sessionStorage.removeItem("SSO_LOGIN");
      await this.backendService.logout(redirectToLogin);
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }
}
