<button
  type="button"
  [ngClass]="'btn btn-' + variant + ' btn-' + size"
  [disabled]="disabled"
  (click)="onClickEvent()"
  class="d-flex align-items-center"
>
<div *ngIf="iconLeftSrc" class="btn-icon-left d-flex align-items-center justify-content-center">
  <img [src]="iconLeftSrc" alt="">
</div>
  {{ text }}
  <div *ngIf="iconRightSrc" class="btn-icon-right d-flex align-items-center justify-content-center">
    <img [src]="iconRightSrc" alt="">
  </div>
</button>
