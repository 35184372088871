import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { AuthenticationGuardFn } from "./global-service/authentication.guard";
import { SessionDetailComponent } from "./pages/session-detail/session-detail.component";

export namespace UrlCollection {
  export const localAuthentication = "local-authentication";
  export const homepage = "homepage";
  export const samlSso = "Saml-sso";
  export const notAuthorized = "not-authorized";
  export const notFound = "not-found";
  export const redirectTo = "";
  export const redirectRequest = "redirectRequest";
  export const forbidden = "403";
  export const sessionDetail = "sessionDetail";
}

const routes: Routes = [
  { path: "", redirectTo: UrlCollection.homepage, pathMatch: "full" },
  {
    path: UrlCollection.samlSso,
    loadChildren: async () => {
      const m = await import("./pages/saml-sso/saml-sso.module");
      return m.SamlSsoModule;
    }
  },
  {
    path: UrlCollection.localAuthentication,
    loadChildren: async () => {
      const m = await import("./pages/local-authentication/local-authentication.module");
      return m.LocalAuthenticationModule;
    }
  },
  {
    path: UrlCollection.forbidden,
    component: ForbiddenComponent
  },
  {
    path: UrlCollection.homepage,
    canActivate: [AuthenticationGuardFn],
    component: HomepageComponent
  },
  {
    path: UrlCollection.sessionDetail + "/:sessionId",
    canActivate: [AuthenticationGuardFn],
    component: SessionDetailComponent
  },
  { path: "**", redirectTo: UrlCollection.notFound }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
