import { Injectable } from "@angular/core";
import { BaseDataService } from "src/ancestors/base-data.service";

@Injectable()
export class UploadService extends BaseDataService {

  protected _uploadFile?: File;

  public set file(value: File | undefined) {
    this._uploadFile = value;
  }
  
  public get file(): File | undefined {
    return this._uploadFile;
  }

  public parseFile(file: File, action: string) {
    const formData = new FormData();

    formData.append(action, file);
    
    return formData;
  }
}