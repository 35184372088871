import { ErrorHandler, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { API_REQUEST_METHODS, DownloadedFile, HttpService } from "src/ancestors/base-api.service";
import { TokenStorageService } from "src/app/global-service/token-storage.service";
import { BaseLoginInfo } from "./user.service";
import { Company, RewardsFileValidationResult, SessionWithCounters, UserLetter } from "mbo-letters-cl";
import { ToastService } from "../shared/components/toast/toast.service";
import { TranslateService } from "../shared/util/translate.service";

export interface UserParams {
  email: string;
  password: string;
  langCode?: string;
  deviceType?: string;
  userAgent?: string;
  createPersistentUserAuth?: string;
}

@Injectable()
export class BackendService extends HttpService {
  constructor() {
    super();
    this.logger.setCaller("BackendService");
    this.createInstance({
      url: this.env.apiBaseUrl,
      minimum: 1500
    });
  }

  protected toast = inject(ToastService);
  private tkStorage: TokenStorageService = inject(TokenStorageService);
  private router: Router = inject(Router);
  private errorHandler = inject(ErrorHandler);
  private translate: TranslateService = inject(TranslateService);

  /** AUTH */

  /**
   * @param loginParam parametri per eseguire il login.
   */
  async localLogin(loginParam: UserParams): Promise<BaseLoginInfo | undefined> {
    try {
      const loginRes = await this.httpClient<BaseLoginInfo>("authn/login", {
        method: API_REQUEST_METHODS.POST,
        body: loginParam
      });


      if (loginRes) {
        return loginRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * Rinnova il token di autenticazione ed invalida il precedente.
   */
  async refreshToken(): Promise<BaseLoginInfo | undefined> {
    try {
      const refreshToken = await this.httpClient<BaseLoginInfo>("authn/refreshToken", {
        method: API_REQUEST_METHODS.POST
      });

      if (refreshToken) {
        return refreshToken;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  async decodeSingleUseToken(singleUseToken: string): Promise<BaseLoginInfo | undefined> {
    try {
      const decodeSingleUseToken = await this.httpClient<BaseLoginInfo>("authn/decodeSingleUseToken", {
        query: { singleUseToken }
      });

      if (decodeSingleUseToken) {
        return decodeSingleUseToken;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  async createSingleUseToken(): Promise<string | undefined> {
    try {
      const createSingleUseToken = await this.httpClient<BaseLoginInfo>("/authn/createSingleUseToken", {
        method: "post"
      });

      if (createSingleUseToken) {
        return createSingleUseToken.token;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * @returns Invalida il token dell'utente e ne impedisce l'uso e il rinnovo.
   */
  public async logout(redirectToLogin?: string): Promise<boolean> {
    try {
      const logoutRes = await this.httpClient<BaseLoginInfo>("authn/logout", {
        method: API_REQUEST_METHODS.POST
      });

      if (logoutRes) {
        this.tkStorage.deleteToken(this.env.localTokenKey);
        await this.router.navigate([redirectToLogin ? redirectToLogin : this.env.loginUrl]);
        return true;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async countSessions(): Promise<number> {
    try {
      const sessionsCount = await this.httpClient<number>("/session/count", {
      });

      return sessionsCount;

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  public async getSessions(fromRecord?: number, numRecords?: number): Promise<SessionWithCounters[]> {
    try {
      const sessions = await this.httpClient<SessionWithCounters[]>("/session/list", {
        query: { fromRecord, numRecords }
      });

      if(sessions?.length) {
        return sessions;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async getSessionDetail(sessionId: string) {
    try {
      const sessionDetail = await this.httpClient<SessionWithCounters>("/session/get", {
        path: [sessionId]
      });

      if (sessionDetail) {
        return sessionDetail;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as SessionWithCounters;
  }

  public async downloadAllLetters(sessionId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/letter/download/all", {
        path: [sessionId],
        noFlickering: true,
        isFileDownload: true
      });

      if (file) {
        return file;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async deleteSession(sessionId: string) {
    try {
      const sessionIsDeleted = await this.httpClient<boolean>("/session/delete", {
        method: "delete",
        path: [sessionId]
      });

      if (sessionIsDeleted) {
        return sessionIsDeleted;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async sendLetters(sessionId: string) {
    try {
      const lettersCount = await this.httpClient<number>("/letter/send", {
        method: "post",
        path: [sessionId]
      });

      if (lettersCount >= 0) {
        return lettersCount;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  public async countLetters(sessionId: string, searchedText?: string | null): Promise<number> {
    try {
      let query = null;
      const lettersCount = await this.httpClient<number>("/letter/count", {
        query: searchedText ? { searchedText: searchedText || "" } : {},
        path: [sessionId]
      });

      return lettersCount;

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  public async getLetters(sessionId: string, fromRecord?: number, numRecords?: number, searchedText?: string | null): Promise<UserLetter[]> {
    try {
      const letters = await this.httpClient<UserLetter[]>("/letter/list", {
        query: searchedText ? { fromRecord, numRecords, searchedText: searchedText } : { fromRecord, numRecords },
        path: [sessionId]
      });

      if(letters?.length) {
        return letters;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async getSelectableCompanies(): Promise<Company[]> {
    try {
      const companies = await this.httpClient<Company[]>("/company/list", {
      });
      
      if(companies?.length) {
        return companies;
      }
      return companies;

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async getSelectableYears(): Promise<number[]> {
    try {
      const years = await this.httpClient<number[]>("/session/selectableYears", {
      });

      if(years?.length) {
        return years;
      }
      return years;

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async downloadLetterTemplatesByCompanyId(companyId: string): Promise<DownloadedFile> {
      try {
        const file = await this.httpClient<DownloadedFile>("/letter/template/download/all", {
          path: [companyId],
          noFlickering: true,
          isFileDownload: true
        });
  
        if (file) {
          return file;
        }
  
      } catch (error: unknown) {
        this.errorHandler.handleError(error);
      }
  
      return {} as DownloadedFile;
  }

  public async downloadLetterByLetterId(letterId: string): Promise<DownloadedFile> {
    try {
      const file = await this.httpClient<DownloadedFile>("/letter/download", {
        path: [letterId],
        noFlickering: true,
        isFileDownload: true
      });

      if (file) {
        return file;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as DownloadedFile;
  }

  public async checkUploadRewardFile(file: FormData, companyId: string): Promise<RewardsFileValidationResult> {
    try {
      const fileValidated = await this.httpClient<RewardsFileValidationResult>("session/reward/validate", {
        method: "post",
        body: file,
        query: {companyId},
        formData: true
      });

      if (fileValidated) {
        return fileValidated;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as any;
  }

  public async createSession(file: FormData, year: number, companyId: string): Promise<string> {
    try {
      const sessionId = await this.httpClient<string>("session/create", {
        method: "post",
        body: file,
        query: {companyId, year},
        formData: true
      });

      if (sessionId) {
        return sessionId;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return "";
  }
}

export class SessionWithCountersExtended extends SessionWithCounters {
  generatedLettersString?: string | null = null;
  totalGeneratedLetters?: number = 0;
  lettersToSendStringForModal?: string | null = null;
}