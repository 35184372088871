import { Component, Input } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";


@Component({
  selector: "tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"]
})
export class TagComponent extends BaseComponent {

  @Input() variant: string | null = null;
  @Input() text: string | null = null;
}
