import { Component, ElementRef, Renderer2, TemplateRef, ViewChild, inject } from "@angular/core";
import { NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { Company } from "mbo-letters-cl";
import { BaseComponent } from "src/ancestors/base/base.component";
import { UrlCollection } from "src/app/app-routing.module";
import { SessionWithCountersExtended } from "src/app/global-service/backend-api.service";
import { ToastService } from "src/app/shared/components/toast/toast.service";
import { UploadService } from "src/app/shared/util/upload.service";
declare var bootstrap: any;


@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"]
})
export class HomepageComponent extends BaseComponent {

  protected uploadService = inject(UploadService);
  protected toast = inject(ToastService);
  private NgbModalService = inject(NgbModal);

  genericLoader: boolean = false;
  sessionsCount: number = 0;
  sessions: SessionWithCountersExtended[] = [];
  fromRecord: number = 0;
  currentPage: number = 1;
  protected readonly pageSizes: number[] = [10, 20, 50];
  protected pageSize = this.pageSizes[0];

  years: number[] = [];
  selectedYear: number | null = null;
  companies: Company[] = [];
  selectedCompany: Company | null = null;
  file: string | null = null;
  @ViewChild("contentSidebar") contentSidebar: any;
  isFileValidated: boolean = false;
  fileData: File | null = null;
  sessionId: string | null = null;
  yearSelected: boolean = false;
  companySelected: boolean = false;
  validateFileErrors: string | null = null;
  modalReferenceValidateFileErrorsSession: any;
  yearsLoader: boolean = false;
  fileUploadLoader: boolean = false;

  private offcanvasService = inject(NgbOffcanvas);

  constructor(
  ) {
    super();
    this.logger.setCaller("HomepageComponent");
  }

  override async ngOnInit(): Promise<void> {
    await this.countSessions();
  }

  protected async countSessions() {
    this.genericLoader = true;
    this.sessionsCount = await this.backendService.countSessions();
    if (this.sessionsCount) {
      await this.getSessions();
    } else {
      this.genericLoader = false;
    }
  }

  protected async getSessions() {
    this.sessions = await this.backendService.getSessions(this.fromRecord, this.pageSize);

    for (let i = 0; i < this.sessions.length; i++) {
      let session = this.sessions[i];

      session.generatedLettersString = "";
      if (session.lettersCountsWithTypes?.length) {
        for (let j = 0; j < session.lettersCountsWithTypes.length; j++) {
          session.generatedLettersString = session.generatedLettersString += session.lettersCountsWithTypes[j].letterTypeName + " (" + session.lettersCountsWithTypes[j].lettersCount + "), ";
        }
        session.generatedLettersString = session.generatedLettersString.substring(0, session.generatedLettersString.length - 2);
      }
    }
    this.genericLoader = false;
  }

  protected async getSelectableYears() {
    this.yearsLoader = true;
    this.years = await this.backendService.getSelectableYears();
    this.yearsLoader = false;
  }

  protected async getSelectableCompanies() {
    this.companies = await this.backendService.getSelectableCompanies();
  }

  protected async downloadLetterTemplates() {
    if (this.selectedCompany) {
      let file = await this.backendService.downloadLetterTemplatesByCompanyId(this.selectedCompany.companyId);
      if (file) {
        let a = document.createElement("a");
        file.fileName = file.fileName.substring(1, file.fileName.length - 1);
        a.href = file.fileUrl;
        a.download = file.fileName;
        a.click();
        URL.revokeObjectURL(a.href);
      } else {
        this.toast.error(this.translateService.instant("createSession.TEMPLATE_LETTERS_NOT_FOUND"));
      }
    }
  }

  protected async goToSessionDetail(sessionId: string) {
    await this.router.navigate([UrlCollection.sessionDetail, sessionId]);
  }

  onPageChange(selectedPage: number) {
    this.currentPage = selectedPage;
    this.fromRecord = (this.currentPage - 1) * this.pageSize;
    this.genericLoader = true;
    this.getSessions();
  }

  onOptionChanged(pageSizeChoosed: number) {
    this.currentPage = 1;
    this.fromRecord = 0;
    this.pageSize = pageSizeChoosed;
    this.genericLoader = true;
    this.getSessions();
  }

  openCreateSessionSidebar() {
    this.offcanvasService.open(this.contentSidebar, { backdrop: "static", position: "end", ariaLabelledBy: 'content-sidebar-title' });
    setTimeout(() => {
      this.contentSidebar;
    }, 1000)
    
    this.getSelectableYears();
    this.getSelectableCompanies();
  }

  onSelectedYearChanged(yearSelected: number) {
    this.selectedYear = yearSelected;
  }

  onSelectedCompanyChanged(companySelected: Company) {
    this.selectedCompany = companySelected;
  }

  downloadTracciato() {
    window.open("assets/documents/Tracciato Lettere MBO Claims.xlsx", "_blank");
  }

  protected async closeSidebar(confirm?: boolean) {
    this.offcanvasService.dismiss(this.contentSidebar);

    if (confirm) {
      const formData = new FormData();
      if (this.fileData) formData.append("file", this.fileData);
      if (formData && this.selectedYear && this.selectedCompany) this.sessionId = await this.backendService.createSession(formData, this.selectedYear, this.selectedCompany?.companyId);
      if (this.sessionId) {
        this.toast.success(this.translateService.instant("sessionDetail.SUCCESS_CREATED_SESSION"));
        this.goToSessionDetail(this.sessionId);
      } else {
        this.toast.error(this.translateService.instant("sessionDetail.ERROR_CREATING_SESSION"));
      }
    } else {
      this.selectedYear = null;
      this.selectedCompany = null;
      this.fileData = null;
      this.isFileValidated = false;
      this.yearSelected = false;
      this.companySelected = false;
    }
  }

  protected async onFileChange(eventUpload: any, content: TemplateRef<any>) {

    
    eventUpload.currentTarget.className += " black-color";
    this.fileData = eventUpload.target.files[0];
    const formData = new FormData();

    if (this.fileData) formData.append("file", this.fileData);

    this.isFileValidated = false;
    if (this.selectedCompany) {
      this.fileUploadLoader = true;
      let validatedFile = await this.backendService.checkUploadRewardFile(formData, this.selectedCompany.companyId);

      if (validatedFile.errors?.length) {
        this.validateFileErrors = "";
        for (let i = 0; i < validatedFile.errors.length; i++) {
          this.validateFileErrors += this.translateService.instant("errors." + validatedFile.errors[i].errorCode) + ": riga " +  validatedFile.errors[i].rowNumber + " - valore campo: " + validatedFile.errors[i].fieldValue + "<br>";
        }
        this.openValidateFileErrorsModal(content);
      } else {
        this.validateFileErrors = "";
        this.isFileValidated = true;
      }
      this.fileUploadLoader = false;
    }
  }

  openValidateFileErrorsModal(content: TemplateRef<any>) {
    this.modalReferenceValidateFileErrorsSession = this.NgbModalService.open(content, { ariaLabelledBy: "modal-validate-file-errors-title" });
  }

  closeValidateFileErrorsModal() {
    this.modalReferenceValidateFileErrorsSession.close();
  }

  disabledContinueCreateSession() {
    if (this.yearSelected && !this.companySelected) {
      return !this.selectedCompany;
    } else if (this.yearSelected && this.companySelected) {
      return (!this.selectedYear ||
        !this.selectedCompany ||
        !this.fileData ||
        !this.isFileValidated)
    } else {
      return !this.selectedYear;
    }
  }

  continueCreateSession() {
    if (this.selectedYear && !this.selectedCompany) {
      this.yearSelected = true;
    } else if (this.selectedYear && this.selectedCompany && !this.fileData && !this.isFileValidated) {
      this.companySelected = true;
      this.yearSelected = true;
    } else if (this.selectedYear && this.selectedCompany && this.fileData && this.isFileValidated) {
      this.closeSidebar(true);
    }
  }

  clickInputFile(event: any) {
    event.target.value = "";
  }
}