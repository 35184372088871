import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbDatepickerModule, NgbPagination, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "./pipe/translation.filter.pipe";
import { HeaderComponent } from "./components/header/header.component";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { TitleSubtitlePageComponent } from "./components/title-subtitle-page/title-subtitle-page.component";
import { MboButtonComponent } from "./components/button/mbo-button.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { TagComponent } from "./components/tag/tag.component";
import { LetterCounterComponent } from "./components/letter-counter/letter-counter.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { LinkButtonComponent } from "./components/link-button/link-button.component";
import { ToastComponent } from "./components/toast/toast.component";
import { RemoveAfterDirective } from "./directives/remove-after.directive";
import { SelectComponent } from "./components/select/select.component";


@NgModule({
  declarations: [
    TranslatePipe,
    HeaderComponent,
    TitleSubtitlePageComponent,
    ClickOutsideDirective,
    MboButtonComponent,
    LoaderComponent,
    TagComponent,
    LetterCounterComponent,
    PaginationComponent,
    LinkButtonComponent,
    ToastComponent,
    RemoveAfterDirective,
    SelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    RouterModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbPaginationModule
  ],
  exports: [
    TranslatePipe,
    HeaderComponent,
    TitleSubtitlePageComponent,
    ClickOutsideDirective,
    MboButtonComponent,
    LoaderComponent,
    TagComponent,
    LetterCounterComponent,
    PaginationComponent,
    LinkButtonComponent,
    ToastComponent,
    RemoveAfterDirective,
    SelectComponent
  ],
  providers: []
})
export class SharedModule { }
