import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";
import { Sizes, Variants } from "../../util/general-types";


@Component({
  selector: "mbo-button",
  templateUrl: "./mbo-button.component.html",
  styleUrls: ["./mbo-button.component.scss"]
})
export class MboButtonComponent extends BaseComponent {

  @Input() variant: Variants = "secondary-light";
  @Input() text: string | null = null;
  @Input() size: Sizes = "md";
  @Input() disabled: boolean = false;
  @Input() iconLeftSrc: string | null = null;
  @Input() iconRightSrc: string | null = null;
  @Output() onClick = new EventEmitter<void>();

  protected onClickEvent() {
    if (!this.disabled) this.onClick.emit();
  }
}