<main aria-label="Contenuto principale della pagina" tabindex="-1">
  <div class="content padding-top16">
    <div
      class="d-flex justify-content-start align-items-center margin-bottom10"
    >
      <link-button
        class="cursor-pointer"
        (onClick)="goToHome()"
        variant="primary-red"
        text="Home"
        customClasses="no-underline"
        iconRightSrc="./assets/icons/red-arrow-icon.svg"
      ></link-button>
      <link-button
        customClasses="no-underline cursor-default"
        variant="primary-red"
        text="{{ 'sessionDetail.TITLE' | translate }}"
        iconRightSrc="./assets/icons/red-arrow-icon.svg"
      ></link-button>
    </div>
    <title-subtitle-page
      *ngIf="!genericLoader"
      title="{{ 'sessionDetail.TITLE' | translate }}"
      subtitle="{{
        sessionDetail?.lettersGenerationCompleted
          ? ('sessionDetail.SECOND_SUBTITLE' | translate)
          : ('sessionDetail.FIRST_SUBTITLE' | translate)
      }}"
      facoltativeSubtitle="{{
        sessionDetail?.lettersGenerationCompleted
          ? ('sessionDetail.FACOLTATIVE_SUBTITLE' | translate)
          : ''
      }}"
    ></title-subtitle-page>

    <ng-container *ngIf="!genericLoader && sessionDetail; else loading">
      <div *ngIf="sessionDetail.lettersGenerationCompleted">
        <div class="w-100 d-flex flex-wrap letter-counter-container">
          <letter-counter
            title="{{
              'sessionDetail.TOTAL_NUMBER_GENERATED_LETTERS_TITLE' | translate
            }}"
            subtitle="{{
              'sessionDetail.TOTAL_NUMBER_GENERATED_LETTERS_SUBTITLE'
                | translate
            }}"
            [count]="sessionDetail.totalGeneratedLetters || 0"
          >
          </letter-counter>
          <letter-counter
            *ngFor="
              let lettersCountsWithType of sessionDetail.lettersCountsWithTypes
            "
            title="{{
              ('sessionDetail.LETTERS_OF' | translate) +
                ' ' +
                lettersCountsWithType.letterTypeName
            }}"
            subtitle="{{
              ('sessionDetail.TOTAL_NUMBER_GENERATED_LETTERS_OF' | translate) +
                ' ' +
                lettersCountsWithType.letterTypeName
            }}"
            [count]="lettersCountsWithType.lettersCount"
          ></letter-counter>
          <letter-counter
            title="{{
              'sessionDetail.TOTAL_NUMBER_SENDED_LETTERS' | translate
            }}"
            subtitle="{{
              'sessionDetail.TOTAL_NUMBER_SENDED_LETTERS_SUBTITLE' | translate
            }}"
            [count]="
              sessionDetail.lettersSendingDate
                ? sessionDetail.totalGeneratedLetters || 0
                : 0
            "
          ></letter-counter>
        </div>

        <p class="subtitle margin-bottom10">
          {{ "sessionDetail.DISPONIBLE_ACTIONS" | translate }}
        </p>

        <div
          class="button-action-container-mid w-100 d-flex justify-content-between margin-bottom40"
        >
          <mbo-button *ngIf="!downloadLettersLoader"
            (onClick)="downloadAllLetters()"
            [variant]="'secondary-ghost-light'"
            text="{{ 'sessionDetail.DOWNLOAD_ALL_LETTERS' | translate }}"
            [size]="'md'"
            [iconLeftSrc]="'./assets/icons/download-outline.svg'"
          ></mbo-button>
          <div *ngIf="downloadLettersLoader">
            <loader marginBlock="30px"></loader>
          </div>
          <div
            *ngIf="!sessionDetail.lettersSendingDate"
            class="d-flex align-items-center"
          >
            <mbo-button
              (onClick)="openDeleteSessionModal(modalDeleteSessionContent)"
              [variant]="'secondary-ghost-light'"
              text="{{ 'sessionDetail.DELETE_SESSION' | translate }}"
              [size]="'md'"
              class="margin-right16"
            ></mbo-button>
            <mbo-button
              (onClick)="openSendLettersModal(modalSendLettersContent)"
              [variant]="'secondary-light'"
              text="{{ 'sessionDetail.SEND_LETTERS' | translate }}"
              [size]="'md'"
            ></mbo-button>
          </div>
        </div>

        <ng-template #modalDeleteSessionContent let-modal>
          <div class="modal-body">
            <div class="w-100 d-flex justify-content-end">
              <link-button
                class="cursor-pointer"
                (onClick)="closeDeleteSessionModal()"
                variant="primary"
                text="{{ 'generic.CLOSE' | translate }}"
              ></link-button>
            </div>
            <div
              id="modal-delete-session-title"
              class="modal-delete-session-title w-100 d-flex justify-content-start margin-top16 margin-bottom16"
            >
              <p tabindex="0">
                {{ "sessionDetail.DELETE_SESSION" | translate }}
              </p>
            </div>
            <span
              class="delete-session-modal-description margin-top16"
              [innerHTML]="
                'sessionDetail.DELETE_SESSION_DESCRIPTION' | translate
              "
            ></span>
            <div
              class="w-100 d-flex justify-content-end button-action-container"
            >
              <mbo-button
                variant="secondary-ghost-light"
                text="{{ 'generic.ANNULL' | translate }}"
                (onClick)="closeDeleteSessionModal()"
              ></mbo-button>
              <mbo-button
                variant="secondary-light"
                text="{{ 'generic.DELETE' | translate }}"
                (onClick)="closeDeleteSessionModal(true)"
              ></mbo-button>
            </div>
          </div>
        </ng-template>

        <ng-template #modalSendLettersContent let-modal>
          <div class="modal-body">
            <div class="w-100 d-flex justify-content-end">
              <link-button
                class="cursor-pointer"
                (onClick)="closeSendLettersModal()"
                variant="primary"
                text="{{ 'generic.CLOSE' | translate }}"
              ></link-button>
            </div>
            <div
              id="modal-send-letters-title"
              class="modal-send-letters-title w-100 d-flex justify-content-start margin-top16 margin-bottom16"
            >
              <p tabindex="0">
                {{ "sessionDetail.SEND_LETTERS" | translate }}
              </p>
            </div>
            <span
              class="send-letters-modal-description margin-top16"
              [innerHTML]="
                'sessionDetail.SEND_LETTERS_DESCRIPTION'
                  | translate
                    : {
                        totalLetters:
                          sessionDetail.totalGeneratedLetters?.toString(),
                        lettersToSendStringForModal:
                          sessionDetail.lettersToSendStringForModal
                      }
              "
            ></span>
            <div
              class="w-100 d-flex justify-content-end button-action-container"
            >
              <mbo-button
                variant="secondary-ghost-light"
                text="{{ 'generic.ANNULL' | translate }}"
                (onClick)="closeSendLettersModal()"
              ></mbo-button>
              <mbo-button
                variant="secondary-light"
                text="{{ 'generic.CONFIRM' | translate }}"
                (onClick)="closeSendLettersModal(true)"
              ></mbo-button>
            </div>
          </div>
        </ng-template>

        <p class="title">
          {{ "sessionDetail.LETTERS_LIST" | translate }}
        </p>

        <p class="subtitle">
          {{ "sessionDetail.LETTERS_LIST_DESC" | translate }}
        </p>

        <div
          class="search-input-container w-100 d-flex flex-column justify-content-start"
        >
          <p>{{ "sessionDetail.DO_A_SEARCH" | translate }}</p>
          <div class="input-group">
            <input
              (keyup.enter)="searchLetters()"
              [ngClass]="searchText?.length ? 'searching' : ''"
              [(ngModel)]="searchText"
              type="text"
              class="form-control"
              placeholder="{{
                'sessionDetail.SEARCH_FOR_SURNAME_AND_CID' | translate
              }}"
              attr.aria-label="{{
                'sessionDetail.SEARCH_FOR_SURNAME_AND_CID' | translate
              }}"
            />
            <div
              class="input-group-append d-flex align-items-center justify-content-center"
            >
              <button
                (click)="clearSearch()"
                class="btn btn-outline-secondary border-0"
                type="button"
                *ngIf="searchText?.length"
                aria-label="Pulisci"
              >
                <img src="./assets/icons/close-outline.svg" alt="" />
              </button>
              <button
                (click)="searchLetters()"
                class="btn btn-outline-secondary border-0"
                type="button"
                aria-label="Cerca"
              >
                <img src="./assets/icons/search.svg" alt="" />
              </button>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!letterLoader; else loadingLetters">
          <div *ngIf="lettersCount && letters?.length" class="table-responsive">
            <table class="table table-striped table-light">
              <thead>
                <tr class="align-middle">
                  <th scope="col">
                    <p>CID</p>
                  </th>
                  <th scope="col">
                    <p>{{ "sessionDetail.SURNAME_NAME" | translate }}</p>
                  </th>
                  <th scope="col">
                    <p>
                      {{ "sessionDetail.SURNAME_NAME_MANAGER" | translate }}
                    </p>
                  </th>
                  <th scope="col">
                    <p>{{ "sessionDetail.LETTER_TYPOLOGY" | translate }}</p>
                  </th>
                  <th scope="col">
                    <p class="red-text">{{ "homepage.ACTIONS" | translate }}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="align-middle" *ngFor="let letter of letters">
                  <td>
                    <p>{{ letter?.user?.cid || "" }}</p>
                  </td>
                  <td>
                    <p>
                      {{
                        (letter?.user?.surname || "") +
                          " " +
                          (letter?.user?.forename || "")
                      }}
                    </p>
                  </td>
                  <td>
                    <p>
                      {{
                        (letter?.manager?.surname || "") +
                          " " +
                          (letter?.manager?.forename || "")
                      }}
                    </p>
                  </td>
                  <td>
                    <p>{{ letter.letterTypeName || "-" }}</p>
                  </td>
                  <td class="d-flex justify-content-center">
                    <link-button
                      class="cursor-pointer"
                      (onClick)="downloadLetter(letter.letterId)"
                      variant="primary-red"
                      customClasses="gap4"
                      text="{{
                        sessionDetail.lettersSendingDate
                          ? ('sessionDetail.DOWNLOAD_LETTER' | translate)
                          : ('sessionDetail.DOWNLOAD_PREVIEW' | translate)
                      }}"
                      iconRightSrc="./assets/icons/download-outline-red.svg"
                    ></link-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p
            *ngIf="!lettersCount || !letters?.length"
            class="title margin-top60"
          >
            {{ "sessionDetail.NO_LETTERS" | translate }}
          </p>
        </ng-container>

        <ng-template #loadingLetters>
          <loader marginBlock="80px"></loader>
        </ng-template>

        <div
          class="d-flex pagination-container justify-content-between align-items-center"
          *ngIf="lettersCount && letters?.length && !letterLoader"
        >
          <div></div>
          <pagination
            [collectionSize]="lettersCount || 0"
            [maxSize]="3"
            [currentPage]="currentPage"
            [pageSize]="pageSize"
            (pageChange)="onPageChange($event)"
          >
          </pagination>

          <div
            class="pagination-elements-container d-flex align-items-center justify-content-center"
          >
            <custom-select
              [ieFlexProblem]="true"
              [bindModel]="pageSize"
              (onSelectionChanged)="onOptionChanged($event)"
              [items]="pageSizes"
              [attrAriaLabel]="'generic.SELECT' | translate"
              [placeholder]="''"
              [required]="true"
              [labelForId]="'input-change-pagination-session-detail'"
              containerClass="custom-select select-searchbar-admin"
              loadingData="{{ 'generic.ngSelect.LOADING_DATA' | translate }}"
              [virtualScroll]="false"
            >
            </custom-select>
            <p class="">{{ "homepage.ELEMENTS" | translate }}</p>
          </div>
        </div>
      </div>

      <div
        class="generation-letters-in-progress-container d-flex flex-column justify-content-center align-items-start"
        *ngIf="!sessionDetail.lettersGenerationCompleted"
      >
        <p class="title">
          {{ "sessionDetail.LETTERS_GENERATED_IN_PROGRESS" | translate }}
        </p>
        <p class="subtitle">
          {{
            "sessionDetail.LETTERS_GENERATED_IN_PROGRESS_DESCRIPTION"
              | translate
          }}
        </p>
      </div>
    </ng-container>

    <ng-template #loading>
      <loader marginBlock="200px"></loader>
    </ng-template>
  </div>
</main>
