import { Component, ContentChild, TemplateRef, inject } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserLetter } from "mbo-letters-cl";
import { BaseComponent } from "src/ancestors/base/base.component";
import { UrlCollection } from "src/app/app-routing.module";
import { SessionWithCountersExtended } from "src/app/global-service/backend-api.service";
import { ToastService } from "src/app/shared/components/toast/toast.service";

@Component({
  selector: "session-detail",
  templateUrl: "./session-detail.component.html",
  styleUrls: ["./session-detail.component.scss"]
})
export class SessionDetailComponent extends BaseComponent {

  protected toast = inject(ToastService);
  private NgbModalService = inject(NgbModal);
  genericLoader: boolean = false;
  lettersCount: number = 0;
  sessionDetail: SessionWithCountersExtended | null = null;
  sessionId: string | null = null;
  letterLoader: boolean = false;
  letters: UserLetter[] = [];
  fromRecord: number = 0;
  currentPage: number = 1;
  protected readonly pageSizes: number[] = [10, 20, 50];
  protected pageSize = this.pageSizes[0];
  searchText: string | null = null;
  modalReferenceDeleteSession: any;
  modalReferenceSendLetters: any;
  downloadLettersLoader: boolean = false;

  constructor() {
    super();
    this.logger.setCaller("SessionDetailComponent");
  }

  override async ngOnInit(): Promise<void> {
    this.sessionId = this.route.snapshot.paramMap.get("sessionId");
    if (!this.sessionId)
      return;
    await this.getSession(this.sessionId);
  }

  protected async getSession(sessionId: string) {
    this.genericLoader = true;
    this.sessionDetail = await this.backendService.getSessionDetail(sessionId);

    if (this.sessionDetail?.lettersGenerationCompleted) {
      await this.countLetters();
    }

    if (this.sessionDetail.sessionId) {
      this.genericLoader = false;

      this.sessionDetail.totalGeneratedLetters = 0;
      this.sessionDetail.lettersToSendStringForModal = "";
      for (let i = 0; i < this.sessionDetail.lettersCountsWithTypes.length; i++) {
        this.sessionDetail.totalGeneratedLetters += this.sessionDetail.lettersCountsWithTypes[i].lettersCount;
        this.sessionDetail.lettersToSendStringForModal += this.sessionDetail.lettersCountsWithTypes[i].lettersCount + " di " + this.sessionDetail.lettersCountsWithTypes[i].letterTypeName + ", ";
      }
      if (this.sessionDetail.lettersToSendStringForModal?.length) {
        this.sessionDetail.lettersToSendStringForModal = this.sessionDetail.lettersToSendStringForModal.substring(0, this.sessionDetail.lettersToSendStringForModal.length - 2);
      }
    } else {
      this.router.navigate([UrlCollection.homepage]);
      this.toast.error(this.translateService.instant("sessionDetail.sessionNotFound"));
    }
  }

  protected async countLetters() {
    this.letterLoader = true;
    if (this.sessionId) this.lettersCount = await this.backendService.countLetters(this.sessionId, this.searchText);
    if (this.lettersCount) {
      await this.getLetters();
    } else {
      this.letters = [];
      this.letterLoader = false;
    }
  }

  protected async getLetters() {
    if (this.sessionId) this.letters = await this.backendService.getLetters(this.sessionId, this.fromRecord, this.pageSize, this.searchText);

    this.letterLoader = false;
  }

  protected async downloadAllLetters() {
    if (this.sessionId) {
      this.downloadLettersLoader = true;
      const file = await this.backendService.downloadAllLetters(this.sessionId);
      this.downloadLettersLoader = false;
      if (file) {
        let a = document.createElement("a");
        file.fileName = file.fileName.substring(1, file.fileName.length - 1);
        a.href = file.fileUrl;
        a.download = file.fileName;
        a.click();
        URL.revokeObjectURL(a.href);
      }
    }
  }

  openDeleteSessionModal(content: TemplateRef<any>) {
    this.modalReferenceDeleteSession = this.NgbModalService.open(content, { ariaLabelledBy: "modal-delete-session-title" });
    setTimeout(() => {
      //this.modalReferenceDeleteSession._windowCmptRef.hostView.rootNodes[0].ariaLabel = this.translateService.instant("sessionDetail.DELETE_SESSION");
  })
  }

  protected async closeDeleteSessionModal(confirm?: boolean) {
    this.modalReferenceDeleteSession.close();
    if (confirm) {
      this.genericLoader = true;
      if (this.sessionId) {
        const isCanceled = await this.backendService.deleteSession(this.sessionId);
        if (isCanceled) {
          this.genericLoader = false;
          this.router.navigate([UrlCollection.homepage]);
        } else {
          this.toast.error(this.translateService.instant("sessionDetail.CANT_DELETE_SESSION"));
          this.genericLoader = false;
        }
      }
    }
  }

  openSendLettersModal(content: TemplateRef<any>) {
    this.modalReferenceSendLetters = this.NgbModalService.open(content);
  }

  protected async closeSendLettersModal(confirm?: boolean) {
    this.modalReferenceSendLetters.close();
    if (confirm) {
      this.genericLoader = true;
      if (this.sessionId) {
        const lettersSended = await this.backendService.sendLetters(this.sessionId);
        if (lettersSended) {
          this.getSession(this.sessionId);
        }
      }
    }
  }

  protected async downloadLetter(letterId: string) {
    const file = await this.backendService.downloadLetterByLetterId(letterId);
    if (file) {
      let a = document.createElement("a");
        file.fileName = file.fileName.substring(1, file.fileName.length - 1);
        a.href = file.fileUrl;
        a.download = file.fileName;
        a.click();
        URL.revokeObjectURL(a.href);
    }
  }

  onPageChange(selectedPage: number) {
    this.currentPage = selectedPage;
    this.fromRecord = (this.currentPage - 1) * this.pageSize;
    this.letterLoader = true;
    this.getLetters();
  }

  onOptionChanged(pageSizeChoosed: number) {
    this.currentPage = 1;
    this.fromRecord = 0;
    this.pageSize = pageSizeChoosed;
    this.letterLoader = true;
    this.getLetters();
  }

  goToHome() {
    this.router.navigate([UrlCollection.homepage]);
  }

  clearSearch() {
    this.searchText = "";
  }

  searchLetters() {
    this.currentPage = 1;
    this.fromRecord = 0;
    this.countLetters();
  }
}